import React, { useEffect, useState } from "react"

import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRule, StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
} from "react-datocms/node_modules/datocms-structured-text-utils"
import { AiOutlineDownload, AiOutlineLink } from "react-icons/ai"
import ComboSearch from "../components/ComboSearch"
import { Navigator } from "../components/LanguageHelpers/Navigator"
import Layout from "../components/layout"
import { getLangCode } from "../functions/langUtils"
const DocsPage = ({
  pageContext,
  data: {
    datoCmsDocsPage: { seo, content, singleString },
    electronics,
    directory,
    files,
    allDatoCmsMiscString: { stringNodes },
  },
}) => {
  const url = typeof window !== "undefined" ? window.location.href : ""

  const [selectedProduct, setSelectedProduct] = useState()
  const [productUrl, setProductUrl] = useState()

  const toggleProduct = product => {
    setSelectedProduct(product)
  }
  const getProductByUrl = () => {
    if (url != "") {
      const hashtagPosition = url.indexOf("#")
      const productbyUrl = url.substring(hashtagPosition + 1)

      setProductUrl(decodeURIComponent(productbyUrl))
      showProductbyUrl()
    }
  }
  const showProductbyUrl = () => {
    if (productUrl != "") {
      electronics.nodes.forEach(ele => {
        if (ele.title == productUrl) {
          toggleProduct(ele)
        }
      })

      directory.nodes.forEach(dir => {
        if (
          dir.relativeDirectory == "Inverter" ||
          dir.relativeDirectory == "catalogues"
        ) {
          if (dir.name == productUrl) {
            toggleProduct(dir)
          }
        }
      })
    }
  }

  const getLangName = locale => {
    switch (getLangCode(locale)) {
      case "en":
      case "EN":
        return stringNodes[0].english
      case "it":
      case "IT":
        return stringNodes[0].italian
      case "es":
      case "ES":
      case "esp":
        return stringNodes[0].spanish
      case "fr":
      case "FR":
        return stringNodes[0].french
      case "PL":
        return "Polish"
      default:
        return ""
    }
  }

  const [docsElements, setDocsElements] = useState([])

  const getDocsElements = () => {
    directory.nodes.map(folder => {
      electronics.nodes.map(ele => {
        if (ele.title === folder.name) {
          setDocsElements(current => [...current, ele])
        }
      })
      if (
        folder.relativeDirectory === "Inverter" ||
        folder.relativeDirectory === "catalogues"
      ) {
        setDocsElements(current => [...current, folder])
      }
    })
  }

  useEffect(() => {
    getProductByUrl()
  }, [productUrl])
  useEffect(() => {
    getDocsElements()
  }, [])
  return (
    <Layout
      pageData={pageContext}
      seoTitle={seo?.seo?.seoTitle}
      seoDescription={seo?.seo?.seoDescription}
      seoImage={seo?.seo?.image?.seoImageUrl}
    >
      <div className="mx-auto my-4  max-w-screen-xl">
        <div className="flex max-h-full w-full flex-col gap-8 px-4 md:flex-row">
          <div className="flex flex-col gap-4 px-4">
            {" "}
            {selectedProduct && (
              <ComboSearch
                selectedOption={selectedProduct}
                onChange={setSelectedProduct}
                placeholder="Search Docs"
                elements={docsElements}
              />
            )}
            <div className="flex max-h-[40vh] flex-col gap-4 overflow-y-auto  md:max-h-screen ">
              <h2 className="mb-2 text-2xl font-semibold text-primary-600">
                {singleString[0].text}
              </h2>
              {directory.nodes.map(folder => {
                return electronics.nodes.map(ele => {
                  if (ele.title == folder.name) {
                    return (
                      <a key={ele.title} href={`#${ele.title}`} target="_top">
                        <div
                          className={`rounded bg-lightGray p-4 shadow hover:opacity-90 ${
                            ele === selectedProduct
                              ? "border-4 border-primary-600"
                              : ""
                          } transition-all focus:ring focus:ring-primary-600`}
                          onClick={() => toggleProduct(ele)}
                        >
                          <h2 className="text-lg font-semibold text-primary-600">
                            {ele.title}
                          </h2>
                          <span className="font-mono font-medium">
                            {ele.tag}
                          </span>
                        </div>
                      </a>
                    )
                  }
                })
              })}

              <h2 className="my-2 text-2xl font-semibold text-primary-600">
                Inverter
              </h2>
              {directory.nodes.map(dir => {
                if (dir.relativeDirectory == "Inverter")
                  return (
                    <a key={dir.name} href={`#${dir.name}`} target="_top">
                      <div
                        className={`rounded bg-lightGray p-4 shadow hover:opacity-90 ${
                          dir === selectedProduct
                            ? "border-4 border-primary-600"
                            : ""
                        } transition-all focus:ring focus:ring-primary-600`}
                        onClick={() => toggleProduct(dir)}
                      >
                        <h2 className="text-lg font-semibold text-primary-600">
                          {dir.name}
                        </h2>
                        <span className="font-mono font-medium">Inverter</span>
                      </div>
                    </a>
                  )
              })}

              <h2 className="my-2 text-2xl font-semibold text-primary-600">
                {singleString[1].text}
              </h2>
              {directory.nodes.map(dir => {
                if (dir.relativeDirectory == "catalogues")
                  return (
                    <a key={dir.name} href={`#${dir.name}`} target="_top">
                      <div
                        className={`rounded bg-lightGray p-4 shadow hover:opacity-90 ${
                          dir === selectedProduct
                            ? "border-4 border-primary-600"
                            : ""
                        } transition-all focus:ring focus:ring-primary-600`}
                        onClick={() => toggleProduct(dir)}
                      >
                        <h2 className="text-lg font-semibold text-primary-600">
                          {dir.name}
                        </h2>
                        <span className="font-mono font-medium">
                          {" "}
                          {singleString[1].text}
                        </span>
                      </div>
                    </a>
                  )
              })}
            </div>
          </div>
          {selectedProduct ? (
            selectedProduct.tag ? (
              <div className="flex flex-1 flex-col items-start justify-start gap-4">
                <div className="flex w-full  flex-col items-start gap-8">
                  <GatsbyImage image={selectedProduct.hero.gatsbyImageData} />

                  {selectedProduct.id ? (
                    <Navigator
                      className="hover:underline hover:decoration-primary-600 hover:opacity-90"
                      locale={pageContext.locale}
                      recordId={selectedProduct.id}
                    >
                      {" "}
                      <div className="flex items-center justify-center gap-4">
                        <h2 className="text-3xl font-bold text-primary-600">
                          {selectedProduct.title}
                        </h2>
                        <AiOutlineLink
                          className="h-full"
                          color="#0b99cd"
                          size={32}
                        />
                      </div>
                    </Navigator>
                  ) : (
                    <h2>{selectedProduct.title}</h2>
                  )}
                </div>
                {files.nodes.map(file => {
                  const fileDir =
                    file.relativeDirectory.match(/([^\/]*)\/*$/)[1]
                  var fileLang = file.name.replace(".pdf", "")
                  var downloadUrl = ""
                  downloadUrl = file.publicURL
                  fileLang = fileLang.slice(-2)
                  if (fileDir == selectedProduct.title) {
                    return (
                      <div className="flex w-full flex-col gap-4 ">
                        <h3 className="text-lg font-semibold">
                          {getLangName(fileLang)}
                        </h3>
                        <a
                          className="flex items-center justify-start gap-4 hover:underline hover:opacity-90"
                          href={downloadUrl}
                          download
                        >
                          {file.name} <AiOutlineDownload size={20} />
                        </a>{" "}
                      </div>
                    )
                  }
                })}
              </div>
            ) : (
              !selectedProduct.image && (
                <div className="flex flex-1 flex-col items-start justify-start gap-4">
                  <h2 className="text-3xl font-bold text-primary-600">
                    {selectedProduct.name}
                  </h2>
                  {files.nodes.map(file => {
                    const fileDir =
                      file.relativeDirectory.match(/([^\/]*)\/*$/)[1]

                    var fileLang = file.name.replace(".pdf", "")
                    var downloadUrl = file.publicURL
                    fileLang = fileLang.slice(-2)
                    if (fileDir == selectedProduct.name) {
                      return (
                        <div className="flex w-full flex-col gap-4 ">
                          <h3 className="text-lg font-semibold">
                            {getLangName(fileLang)}
                          </h3>

                          <a
                            className="flex items-center justify-start gap-4 hover:underline hover:opacity-90"
                            href={downloadUrl}
                            download
                          >
                            {file.name}
                            <AiOutlineDownload size={20} />
                          </a>
                        </div>
                      )
                    }
                  })}
                </div>
              )
            )
          ) : (
            <div className="flex w-full flex-1 flex-col gap-4">
              <StructuredText
                data={content}
                customNodeRules={[
                  renderRule(isHeading, ({ node, children, key }) => {
                    const Heading = `h${node.level}`
                    return (
                      <Heading
                        className="py-2 text-4xl font-bold text-primary-600"
                        key={key}
                      >
                        {children}
                      </Heading>
                    )
                  }),
                  renderRule(isParagraph, ({ node, children, key }) => {
                    return (
                      <div className="max-w-screen-sm ">
                        <p key={key}>{children}</p>
                      </div>
                    )
                  }),
                ]}
              />{" "}
              <ComboSearch
                onChange={setSelectedProduct}
                placeholder="Search Docs"
                elements={docsElements}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default DocsPage

export const query = graphql`
  query DocsPageQuery($locale: String!) {
    datoCmsDocsPage(locale: { eq: $locale }) {
      locale
      id: originalId
      seo: seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      content {
        value
      }
      singleString {
        text
      }
      manual: manual {
        title
        tag
        hero {
          gatsbyImageData(height: 250)
        }
        manual {
          filename
          url
        }
      }
    }
    electronics: allDatoCmsElectronic(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        tag
        id: originalId
        hero {
          gatsbyImageData(height: 250)
        }
      }
    }
    directory: allDirectory {
      nodes {
        name
        dir
        relativeDirectory
      }
    }
    files: allFile {
      nodes {
        name
        dir
        publicURL
        relativeDirectory
      }
    }

    allDatoCmsMiscString {
      stringNodes: nodes {
        english
        italian
        spanish
        french
      }
    }
    catalogue: allDatoCmsCatalogue {
      nodes {
        title
        file {
          filename
          url
        }
        image {
          gatsbyImageData(height: 250)
        }
      }
    }
  }
`
